


































































































import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import store from '@/store/store'
import { Role } from '@/store/modules/role'
import { User } from '@/store/modules/user'

@Component
export default class UserAdminDashboard extends Vue {
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage users',
      to: { name: 'manage-users' }
    },
    {
      text: 'Manage user',
      to: { name: 'manage-user' }
    }
  ]

  user: User = this.$store.getters.getUser
  roles: Role[] = this.$store.getters.getRoles
  selectedRoles: string[] = []
  password: string = ''
  confirmPassword: string = ''

  dismissSecs: number = 5
  dismissCountDown: number = 0

  hasPermissions = hasPermissions

  mounted () {
    this.selectedRoles = this.user.roles.map((r: Role) => {
      return r.key
    })
  }

  rolesChanged () {
    this.user.roles = this.roles.filter((r: Role) => {
      return this.selectedRoles.includes(r.key)
    })
  }

  get canSave () {
    if (this.user.traditionalAuth && this.password.length && this.validateNewPassword().length) {
      return false
    }
    if (!this.user.roles.length) {
      return false
    }
    return true
  }

  saveChanges () {
    if (!this.canSave) {
      return
    }
    this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      if (this.user.traditionalAuth && this.password.length && !this.validateNewPassword().length) {
        this.user.newPassword = this.password
      }
      this.$store.dispatch('saveUser', this.user).then(() => {
        this.dismissCountDown = this.dismissSecs
      })
    })
  }

  validateNewPassword () {
    if (this.password.length < 6) {
      return 'Password must be 6 characters in length'
    }
    if (this.password.length > 30) {
      return 'Password too long'
    }
    if (this.password !== this.confirmPassword) {
      return 'Passwords must match'
    }
    return ''
  }

  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  beforeRouteUpdate (to: any, from: any, next: Function) {
    store.dispatch('loadUser', to.params.userKey).then(() => {
      store.dispatch('loadRoles').then(role => {
        next()
      })
    })
  }

  beforeRouteEnter (to: any, from: any, next: Function) {
    store.dispatch('loadUser', to.params.userKey).then(() => {
      store.dispatch('loadRoles').then(role => {
        next()
      })
    })
  }
}
